
import { Link } from 'react-router-dom'
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const Home = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')



  const run = () => {
    axios.post("https://lions320dapi.emedha.in/run/get", {
      title,
      body
    }).then((res) => {
      if (res.data === 'ok') {
        alert('Notification Successfully send')
        handleClose()
      }
    })
  }


  return (
    <div className='mt-5 pb-5 pt-5'>
      <button onClick={handleShow} style={{ color: 'white', borderRadius: "0.6rem",height:'2.6rem',width:'10rem',border:'none',backgroundColor:'orange', fontWeight: 'bold',marginBottom:'0.9rem' }}>Send Notification</button>
      <div className='text-center container d-block d-md-flex justify-content-between btnContainer '>
        <div>
      
        <Link to='/register-users'><button className='btnStyle shadow'><strong> Register Users</strong></button></Link>
        <br />
          <br />
          <br />
          <Link to='/view-club'><button className='btnStyle shadow'><strong> Club</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/view-member'> <button className='shadow  btnStyle'><strong>  Members</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/notification-message'> <button className='shadow  btnStyle'><strong>Notification Message</strong></button></Link>
          <br />
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
        <div>
          <Link to='/view-events'><button className='shadow  btnStyle'><strong>  Events</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/view-project'> <button className='shadow  btnStyle'><strong>  Project</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/view-cabinet'><button className='shadow  btnStyle'><strong>Cabinet Master</strong></button></Link>
        </div>
        <br />
        <br />
        <br />
        <div>
          <Link to='/multiple'> <button className='shadow  btnStyle'><strong>Multiple</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/international'> <button className='shadow  btnStyle'><strong>International</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/advertisement'> <button className='shadow  btnStyle'><strong>Advertisement</strong></button></Link>
          <br />
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
        <div>
          <Link to='/menu'> <button className='shadow  btnStyle'><strong>Menu</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/dollar'> <button className='shadow  btnStyle'><strong>Dollar</strong></button></Link>
          <br />
          <br />
          <br />
          <Link to='/DG-Diary'> <button className='shadow  btnStyle'><strong>DG-Diary</strong></button></Link>
          <br />
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
      </div>

      <>
        <Modal show={show} onHide={handleClose} size='sm' centered>
          <Modal.Header closeButton>
            <Modal.Title>Send Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input style={{backgroundColor:'white',height:'2.6rem',width:'100%',borderRadius:'0.5rem',marginBottom:10,outlineColor:'orange'}} type="text" className='inputText' placeholder='Enter notification title' onChange={(e) => setTitle(e.target.value)} />
            <input style={{backgroundColor:'white',height:'2.6rem',width:'100%',borderRadius:'0.5rem',marginBottom:10,outlineColor:'orange'}} type="text" className='inputText' placeholder='Enter notification Body' onChange={(e) => setBody(e.target.value)} />
            <button onClick={() => run()} className='notificationButton' style={{ marginTop: "0.4rem",height:'2.6rem',width:'5rem',borderRadius:'0.5rem',backgroundColor:'orange',border:'none',fontWeight:'bold',color:'white'}}>Send</button>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  )
}

export default Home