import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Pagination from '../pagination/Pagination'
import { Link } from 'react-router-dom'
import { FaUsers } from "react-icons/fa6";

const RegisterUsers=()=>{

    const [Register, setRegister] = useState([]);
    const[showPerPage,setShowPerPage]=useState(10)
   

    const [pagination,setPagination]=useState(
      {
        start:0,
        end:showPerPage
      }
    )
   
    const onChangePagination=(start,end)=>{
      setPagination({start:start,end:end})
    }






    const registerUsersData = async () => {
      try {
        const response = await axios.get('https://lions320dapi.emedha.in/register-users');
        setRegister(response.data || []);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    useEffect(() => {
      registerUsersData();
    }, []);
    


return(

    <>
    <h4 style={{color:'black'}}>Register Users <FaUsers style={{color:'rgb(245, 170, 30)'}}/></h4>

    <div style={{marginTop:'1rem'}} className='table-container'>
        
        <table className="styled-table table table-bordered">
         <thead>
           <tr>
             <th>S.No</th>
             <th>Mobile</th>
             <th>Membership No</th>
             <th>clubName Name</th>
           
           </tr>
           
         </thead>
         <tbody className='table-bordered'>
           {
             Register.slice(pagination.start,pagination.end).map((data,index)=>{
               return(
                 <tr>
                   <td>{index+1}</td>
                   <td>{data.mobile}</td>
                   <td>{data.membershipNo}</td>
                   <td>{data.clubName}</td>
                 
                 </tr>
               )
             })
           }
         </tbody>
        </table>
       <Pagination page={showPerPage} onChangePagination={onChangePagination} total={Register.length}/>      
   </div>

   </>
)
}

export default RegisterUsers